import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'gatsby';
// import { isEmpty } from 'lodash';

export default class Element {
  constructor(props) {
    this.generalManager = props.generalManager;

    this.elementInfo = props.elementInfo;

    this.apiKeys = props.apiKeys;

    this.createElement();
  }

  blueBadges() {
    this.element = document.createElement('div');
    this.element.className = 'timeline-animation__blue-badges blue-badges';

    const inner = document.createElement('div');
    inner.className = 'blue-badges__inner';
    this.element.appendChild(inner);

    const title = document.createElement('div');
    title.className = 'blue-badges__title';
    title.innerHTML = `${this.elementInfo[this.apiKeys.startDisplay]} - ${
      this.elementInfo[this.apiKeys.endDisplay]
    }`;
    inner.appendChild(title);

    const description = document.createElement('div');
    description.className = 'blue-badges__description';
    description.innerHTML = this.elementInfo[this.apiKeys.bbTitle];
    // description.innerHTML = this.elementInfo[this.apiKeys.imageDescription];
    inner.appendChild(description);

    const helperDiv = document.createElement('div');
    ReactDOM.render(
      <Link
        href={`/periode/${this.elementInfo[this.apiKeys.bbId]}`}
        className='blue-badges__link'
      >
        {this.elementInfo[this.apiKeys.bbTitle]}
      </Link>,
      helperDiv
    );
    this.element.appendChild(helperDiv);

    this.element.setAttribute('x', this.elementInfo[this.apiKeys.x]);
    this.element.setAttribute('y', this.elementInfo[this.apiKeys.y]);
  }

  imgTiles() {
    this.element = document?.createElement('div');
    this.element.className = 'timeline-animation__img-tiles img-tiles';

    this.element.setAttribute('x', this.elementInfo[this.apiKeys.x]);
    this.element.setAttribute('y', this.elementInfo[this.apiKeys.y]);

    const imgWrapper = document?.createElement('div');
    imgWrapper.className = 'img-tiles__img-wrapper';

    this.element.appendChild(imgWrapper);

    // let elInfoImg = this.elementInfo[this.apiKeys.img];
    // console.log(this.elementInfo[this.apiKeys.img])
    // console.log(typeof this.elementInfo[this.apiKeys.img])
    // if (!isEmpty(elInfoImg)) {
    // }
    if (this.elementInfo[this.apiKeys.img]) {
      imgWrapper?.appendChild(this.elementInfo[this.apiKeys.img]);
    } else {
      imgWrapper.className = 'img-tiles__img-wrapper img-tiles__no-image';
    }

    const descriptionDiv = document.createElement('div');
    descriptionDiv.className = `img-tiles__description ${
      this.elementInfo[this.apiKeys.x] < 0 ? 'left' : 'right'
    }`;
    this.element.appendChild(descriptionDiv);

    const descriptionTitle = document.createElement('div');
    descriptionTitle.className = 'img-tiles__description-title';
    descriptionTitle.innerHTML = this.elementInfo[this.apiKeys.startDisplay];
    descriptionDiv.appendChild(descriptionTitle);

    const descriptionBody = document.createElement('div');
    // descriptionBody.innerHTML = this.elementInfo[this.apiKeys.imageDescription];
    descriptionBody.innerHTML = this.elementInfo[this.apiKeys.bbTitle];
    descriptionBody.className = 'img-tiles__description-body';
    descriptionDiv.appendChild(descriptionBody);

    this.clickFunction = () => {
      this.generalManager.fullSizeImgManager.show(
        this.elementInfo,
        this.element
      );
    };

    this.element.addEventListener('click', this.clickFunction);
  }

  destroy() {
    this.objectCSS.removeFromParent();
    this.element.removeEventListener('click', this.clickFunction);
    this.element.innerHTML = '';
  }

  brownBadges() {
    this.element = document.createElement('div');
    this.element.className = 'timeline-animation__brown-badges brown-badges';

    const inner = document.createElement('div');
    inner.className = 'brown-badges__inner';
    this.element.appendChild(inner);

    const description = document.createElement('div');
    description.className = 'brown-badges__description';
    description.innerHTML = this.elementInfo[this.apiKeys.bbTitle];
    inner.appendChild(description);

    const helperDiv = document.createElement('div');
    ReactDOM.render(
      <Link
        href={`/evenement/${this.elementInfo[this.apiKeys.bbId]}`}
        className='brown-badges__link'
      >
        {this.elementInfo[this.apiKeys.bbTitle]}
      </Link>,
      helperDiv
    );
    this.element.appendChild(helperDiv);

    this.element.setAttribute('x', this.elementInfo[this.apiKeys.x]);
    this.element.setAttribute('y', this.elementInfo[this.apiKeys.y]);
  }

  createElement() {
    if (this.elementInfo[this.apiKeys.type] === this.apiKeys.imgTiles) {
      this.imgTiles();
    }

    if (this.elementInfo[this.apiKeys.type] === this.apiKeys.blueBadges) {
      this.blueBadges();
    }
    if (this.elementInfo[this.apiKeys.type] === this.apiKeys.brownBadges) {
      this.brownBadges();
    }

    this.objectCSS = new CSS3DObject(this.element);

    this.objectCSS.position.x = this.elementInfo[this.apiKeys.x];
    this.objectCSS.position.y = this.elementInfo[this.apiKeys.y];
    this.objectCSS.position.z = -+this.elementInfo[
      this.apiKeys.normalPositions
    ];

    this.generalManager.sceneManager.group.add(this.objectCSS);
  }
}
