import styled from 'styled-components';

export const StyledFilteredSearchInfo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
`;

export const ButtonSearch = styled.button`
  width: 210px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.blue};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ButtonSearchSmall = styled.button`
  padding: 30px;
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.blue};
  background-image: url("/images/search.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const FiltredInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.h4};
  line-height: 18px;
  color: ${(props) => props.theme.colors.blue};
`;

export const Container = styled.div`
  width: 700px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledResetButton = styled.div`
  font-size: ${(props) => props.theme.fonts.h4};
  line-height: 19px;
  color: ${(props) => props.theme.colors.purple};
  text-decoration-color: ${(props) => props.theme.colors.purple};
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  cursor: pointer;
`;

export const StyledCopyURL = styled(StyledResetButton)`
  display: flex;

  svg {
    height: 22px;
    margin-right: 5px;

    path {
      fill: #7E8BC3;
    }
  }
`

export const TitlesContainer = styled.div`
  position: absolute;
  top: 110px;
  left: 100px;
  font-size: ${(props) => props.theme.fonts.h1_md};
  line-height: 48px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.secondary};
`;
