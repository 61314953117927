import React from 'react';
import {
  StyledCheckboxItem,
  Label,
  Checkbox,
  Checkmark,
} from './styles.js'

const CheckboxItem = ({item, handleOnChange, isChecked}) => {

  const handleCkeck = () =>{
    handleOnChange(item, !isChecked);
  }

  return (
    <StyledCheckboxItem >
      <Label htmFor={item?.id}>{item?.terme}
        <Checkbox
          type="checkbox"
          id={item?.id}
          onChange={handleCkeck}
          checked={isChecked}
        >
        </Checkbox>
        <Checkmark className="checkmark"></Checkmark>
      </Label>
    </StyledCheckboxItem>
  )
}

export default CheckboxItem;