import gsap from 'gsap';
import { contain } from 'intrinsic-scale';
import loader from 'load-asset';

export default class FullSizeImg {
  constructor(props) {
    this.generalManager = props.generalManager;
    this.apiKeys = props.apiKeys;

    this.fullSizeImg = props.fullSizeImg;
    this.closeBtn = props.closeBtn;
    this.imgWrapper = props.imgWrapper;
    this.description = props.description;
    this.descriptionTitle = props.descriptionTitle;
    this.descriptionBody = props.descriptionBody;
    this.descriptionLink = props.descriptionLink;

    this.isOpen = false;

    this.setHandlers();
  }

  show(elementInfo, element) {
    this.isOpen = true;

    this.imgWrapper.innerHTML = '';
    this.elementInfo = elementInfo;
    this.element = element;

    const maxWidth = 1920;
    const padding = 50;
    const descriptionWidth = 400;

    this.setBoundings();

    const currentWidth =
      Math.min(this.generalManager.width, maxWidth) -
      padding * 2 -
      descriptionWidth;
    const currentHeight = this.generalManager.height - padding * 2;

    const imgWidth = this.elementInfo[this.apiKeys.img] ? this.elementInfo[this.apiKeys.img].width : 80;
    const imgHeight = this.elementInfo[this.apiKeys.img] ? this.elementInfo[this.apiKeys.img].height : 80;

    const { width, height, x, y } = contain(
      currentWidth,
      currentHeight,
      imgWidth,
      imgHeight
    );

    const afterZoomTop = y + padding;
    const afterZoomLeft = x + padding;

    if (this.elementInfo[this.apiKeys.img]) {
      const fullImage = this.elementInfo[this.apiKeys.img].cloneNode();
      this.imgWrapper.appendChild(fullImage);

      this.fullSizeImg.classList.add('is-visible');
      this.fullSizeImg.classList.add('is-loading');

      let pathStr = this.elementInfo[this.apiKeys.fullImgPath];
      const pathArr = pathStr.split('');
      pathArr.splice(pathStr.lastIndexOf(',/0/default.jpg') - 3, 3, '1200');
      pathStr = pathArr.join('');

      loader(pathStr).then((img) => {
        this.fullSizeImg.classList.remove('is-loading');
        this.imgWrapper.replaceChild(img, fullImage);
      });
    } else {
      const fullImage = document.createElement('div');
      fullImage.classList.add('full-size-img__no-image')
      this.imgWrapper.appendChild(fullImage);

      this.fullSizeImg.classList.add('is-visible');
    }

    gsap
      .timeline()
      .to(
        this.imgWrapper,
        {
          duration: 0.3,
          width,
          height,
          left: afterZoomLeft,
          top: afterZoomTop,
        },
        0
      )
      .to(this.fullSizeImg, { duration: 0.2, autoAlpha: 1 }, 0)
      .to(this.element, { duration: 0, autoAlpha: 0 }, 0)
      .to(this.description, { duration: 0.3, left: width + afterZoomLeft }, 0)
      .to(this.description, { duration: 0, top: afterZoomTop + height / 2 }, 0);

    this.descriptionTitle.innerHTML = this.elementInfo[
      this.apiKeys.startDisplay
    ];
    this.descriptionBody.innerHTML = this.elementInfo[
      this.apiKeys.bbTitle
    ];
    this.descriptionLink.innerHTML = 'En savoir plus';
    this.descriptionLink.href = `/evenement/${
      this.elementInfo[this.apiKeys.bbId]
    }`;

    // this.description.style.top = `${afterZoomTop}px`;
    // this.description.style.left = `${width + afterZoomLeft}px`;
  }

  hide() {
    this.isOpen = false;
    this.fullSizeImg.classList.remove('is-visible');
    const { top, left, width, height } = this?.element?.getBoundingClientRect();

    gsap
      .timeline()
      .to(this.imgWrapper, { duration: 0.3, width, height, left, top }, 0)
      .to(this.fullSizeImg, { duration: 0.3, autoAlpha: 0 }, 0.2)
      .to(this.element, { duration: 0.1, autoAlpha: 1 }, 0.2)
      .to(this.description, { duration: 0.3, left: '100%' }, 0);
    // .to(this.description, {duration: 0, top: afterZoomTop + height/2}, 0.3);
  }

  setHandlers() {
    this.clickFunction = this.hide.bind(this);
    this.keydownFunction = this.keydown.bind(this);
    this.closeBtn.addEventListener('click', this.clickFunction);
    window.addEventListener('keydown', this.keydownFunction);
  }

  destroy() {
    this.closeBtn.removeEventListener('click', this.clickFunction);
    window.removeEventListener('keydown', this.keydownFunction);
  }

  keydown({ key }) {
    if (key === 'Escape' && this.isOpen) {
      this.hide();
    }
  }

  setBoundings() {
    const { top, left, width, height } = this.element.getBoundingClientRect();

    this.imgWrapper.style.top = `${top}px`;
    this.imgWrapper.style.left = `${left}px`;
    this.imgWrapper.style.width = `${width}px`;
    this.imgWrapper.style.height = `${height}px`;
  }
}
