import styled from 'styled-components';
import Typography from 'components/UI/Typography';
import { Button as SearchButton } from '../Search/styles';

export const StyledFilterAccordionButton = styled(SearchButton)`
  margin-left: 0;
  margin-top: 15px;
`

export const StyledFilterAccordion = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% - 335px);
  z-index: 6;
  width: 670px;
  height: fit-content;
  padding: 30px 20px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.secondary};

  &::after {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: ${(props) => props.theme.colors.light_purple};
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Typography)`
  font-size: ${(props) => props.theme.fonts.h3};
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  display: block;
  padding-top: 30px;
`;

export const Button = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer
`;
