import loader from 'load-asset';

function startYear(dataArr, yearStart, setKey) {
  return dataArr.map((item) => {
    item[setKey] = +item[yearStart] - 1;
    return item;
  });
}

function sortDatas(dataArr, key) {
  return dataArr.sort((i1, i2) => {
    return +i1[key] - +i2[key];
  });
}

function setField(dataArr, key, field) {
  return dataArr.map((item) => {
    item[key] = field;
    return item;
  });
}

function parseSrcs(dataArr, srcKey) {
  const imgSrcMap = {};
  const imgSrcArr = [];

  dataArr.forEach((item) => {
    if (!item || !item[srcKey]) return;

    imgSrcMap[item[srcKey]] = item;
    imgSrcArr.push(item[srcKey]);
  });

  return { imgSrcMap, imgSrcArr };
}

function loadImgs(srcArray, onProgress, onLoad) {
  loader.any(srcArray, onProgress).then(onLoad);
}

export default class MainApiParser {
  constructor(props) {
    this.mainData = props.mainData;
    this.onProgressCallback = props.onProgressCallback;
    this.onLoadCallback = props.onLoadCallback;
    this.prepearedData = props.prepearedData;

    this.apiKeys = props.apiKeys;

    this.allDatas = [];

    this.imgTiles = [];
    this.blueBadges = [];
    this.brownBadges = [];

    this.prepear();

    loadImgs(
      this.imgParsedDatas.imgSrcArr,
      this.onProgress.bind(this),
      this.onLoad.bind(this)
    );
  }

  prepear(data = this.mainData) {

    
    this.imgTiles = data.Events;
    this.blueBadges = data.BackgroundEvents || [];
    this.brownBadges = data.TemporaryActivities || [];

    this.imgTiles = setField(
      this.imgTiles,
      this.apiKeys.type,
      this.apiKeys.imgTiles
    );
    this.blueBadges = setField(
      this.blueBadges,
      this.apiKeys.type,
      this.apiKeys.blueBadges
    );
    this.brownBadges = setField(
      this.brownBadges,
      this.apiKeys.type,
      this.apiKeys.brownBadges
    );

    this.blueBadges = startYear(
      this.blueBadges,
      this.apiKeys.startDisplayYear,
      this.apiKeys.year
    );

    this.imgParsedDatas = parseSrcs(this.imgTiles, this.apiKeys.thumbnailPath);
  }

  onProgress({ progress }) {
    this.onProgressCallback(progress);
  }

  onLoad(assets) {
    assets.forEach((img) => {

      if (!img || !img.currentSrc) return;

      const item = this.imgParsedDatas.imgSrcMap[img.currentSrc];

      const imgCurrentSrc = img.currentSrc

      item[this.apiKeys.img] = img;
      item[this.apiKeys.src] = imgCurrentSrc;
      item[this.apiKeys.width] = img.width;
      item[this.apiKeys.height] = img.height;

      if (this.prepearedData)
      this.prepearedData.forEach(el => {
        if (el[this.apiKeys.src] === img.currentSrc) el[this.apiKeys.img] = img;
      });
    });

    this.allDatas = [...this.allDatas, ...this.blueBadges, ...this.brownBadges, ...this.imgTiles];

    this.allDatas = sortDatas(this.allDatas, this.apiKeys.year);

    if (this.prepearedData) this.onLoadCallback(this.prepearedData);
    else this.onLoadCallback(this.allDatas);
  }

  sortByYear(data, startYear, finishYear) {
    return data.filter(
      (item) =>
        item[this.apiKeys.year] >= +startYear &&
        item[this.apiKeys.year] <= +finishYear
    );
  }

  sortById(data, arrayOfId) {
    return data.filter((item) => {
      for (let i = 0; i < arrayOfId.length; i++) {
        if (arrayOfId[i] === item[this.apiKeys.bbId]) return true;
      }
      return false;
    });
  }
}
