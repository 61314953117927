import $ from 'jquery';

const tutorialFunctions = {
    addTutorial: function(){
    	const tutorialCode = `<div id="tutorialFullPage"></div>
								<div id="tutorialContainer" class="tutorialContainer">
								    <span id="deleteTutorial">X</span></br>
								    <iframe id="tutorialFrame" width="100%" height="100%" src="https://www.youtube.com/embed/seRTemE9HXw" title="BAnQ Éducation : les Lignes du temps du Québec" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
								</div>`;
		$('body').prepend(tutorialCode);
		$( "#deleteTutorial, #tutorialFullPage" ).bind( "click", function() {
			$("#tutorialFullPage").remove();
    		$("#tutorialContainer").remove();
		});
    },
    tutorialOnLoad: function(){
    	console.log(sessionStorage.getItem("firstLoad"));
    	if (!localStorage.hasOwnProperty("firstLoad")){
    		sessionStorage.setItem("firstLoad", "done");
			tutorialFunctions.addTutorial();
		}
    		
    }
}

export default tutorialFunctions;