import { debounce } from 'underscore';

import Timeline from './Timeline';
import Scene from './Scene';

import MainParser from './api-parsers/mainParser';

import Element from './Element';
import FullSizeImg from './FullSizeImg';

function lerp(value1, value2, amount1) {
  let amount = amount1;
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;
  return value1 + (value2 - value1) * amount;
}

export default class HandoffAnimation {
  constructor(props) {
    this.fullData = props.fullData;
    this.counter = 0;
    // --------- BEGIN DOM
    this.DOM = {
      animation: props.animation,
      sceneContainer: props.sceneContainer,
      timeline: props.timeline,
      yearCursor: props.yearCursor,

      firstScreen: props.firstScreen,
      progressProcent: props.progressProcent,

      arrowTop: props.arrowTop,
      arrowBottom: props.arrowBottom,

      fullSizeImg: props.fullSizeImg,
      closeBtn: props.closeBtn,
      imgWrapper: props.imgWrapper,
      description: props.description,
      descriptionTitle: props.descriptionTitle,
      descriptionBody: props.descriptionBody,
      descriptionLink: props.descriptionLink,
    };
    // --------- END DOM

    // --------- BEGIN settings
    this.settings = {
      firstYear: 1483.4,
      lastYear: 2033.3,

      speed: 0.01,
      focusDistance: 10,

      ease: 0.05,

      maxNumberInYear: 1,

      positionDuration: 5,

      visibleImages: 4,
      fullVisibleImages: 2,

      apiKeys: {
        thumbnailPath: 'ThumbnailPath',
        fullImgPath: 'FeatureImagePath',
        startDisplayYear: 'StartDisplayYear',
        endDisplayYear: 'EndDisplayYear',
        startDisplay: 'StartDisplay',
        endDisplay: 'EndDisplay',
        year: 'Year',

        imgTiles: 'imgTiles',
        blueBadges: 'blueBadges',
        brownBadges: 'brownBadges',

        imageDescription: 'ImageCredit',
        imageBtnText: 'LinkText',
        imageBtnLink: 'LinkTarget',

        bbTitle: 'Title',
        bbDescription: 'BAnQDescription',
        bbId: 'EventId',

        type: 'handoff-type',
        img: 'handoff-img',
        src: 'handoff-src',
        width: 'handoff-width',
        height: 'handoff-height',

        normalPositions: 'handoff-normal-position',
        x: 'handoff-x',
        y: 'handoff-y',
      },
    };
    // --------- END settings

    // --------- BEGIN stage
    this.stage = {
      isInited: false,
      isLoaded: false,
      play: true,

      // startPos: sessionStorage.getItem('position') || 0,
      startPos: Number.isNaN(+sessionStorage.getItem('position'))
        ? 0
        : +sessionStorage.getItem('position'),

      currentPosition: 0,
      currentPositionLeap: 0,

      currentYear: 0,
      currentYearLeap: 0,

      currentProcent: 0,
      currentProcentLeap: 0,

      firstElementPosition: 0,
      lastElementPosition: 0,

      firstElementYear: 0,
      lastElementYear: 0,

      mouseX: 0,
      mouseY: 0,

      progress: 0,
    };
    // --------- END stage

    // --------- BEGIN storage
    this.storage = {
      managers: [],
      onProgressFunctions: [],
      onLoadFunctions: [],

      elements: [],
      normalPositions: [],
    };
    // --------- END storage

    this.events = {
      onPositions: {}
    }
  }

  

  // --------- BEGIN PUBLIC METHODS
  getAllData() {
    return this.apiParser.allDatas;
  }

  getCurrentData() {
    const data = this.storage.tempElements || this.storage.elements;
    return data.map((el) => {
      const newElement = {};
      for (const [key, value] of Object.entries(el)) {
        if (key !== 'elementManager') newElement[key] = value;
      }
      return newElement;
    });
  }

  getCurrentYears() {
    const data = this.storage.tempElements || this.storage.elements;
    return {
      first: Math.floor(data?.[0]?.[this.settings.apiKeys.year]),
      last: Math.floor(data?.[data.length - 1]?.[this.settings.apiKeys.year]),
    };
  }

  sortByYear(data, startYear, finishYear) {
    return this.apiParser.sortByYear(
      data,
      startYear || this.settings.firstYear,
      finishYear || this.settings.lastYear
    );
  }

  sortById(data, arrayOfId) {
    return this.apiParser.sortById(data, arrayOfId);
  }

  restartWithNewData(prepearedData) {
    
    sessionStorage.setItem('position', 0);
    
    this.stage.startPos = 0;
    // this.stage.currentPosition = 3;
    this.stage.currentPositionLeap = 30;
    this.settings.ease = 0.03;
    // this.updatePositionLeap();
    // this.updateCurrentYearLeap();
    // this.updateCurrentProcentLeap();

    this.storage.tempElements = prepearedData; 

    this.storage.elements.forEach((el) => el.elementManager.destroy());
    this.storage.elements = this.storage.tempElements;

    this.createElements();
    this.updatePositionLeap();
    this.updateCurrentYearLeap();
    this.updateCurrentProcentLeap();

    delete this.storage.tempElements;
    
    this.events.onPositions[this.stage.currentPositionLeap] = () => {
      // this.stage.currentPositionLeap = 30;
      this.settings.ease = 0.05;

      this.events.onPositions[this.stage.currentPositionLeap] = null;
    }
 

    // this.setYear(prepearedData[0][this.settings.apiKeys.year])

    
  }

  init() {
    document.querySelector('html').style.overflow = 'hidden';

    this.stage = {
      isInited: false,
      isLoaded: false,
      play: true,

      prepearedData: sessionStorage.getItem('handoff-data')
        ? JSON.parse(sessionStorage.getItem('handoff-data'))?.length
          ? JSON.parse(sessionStorage.getItem('handoff-data'))
          : null
        : null,
      startPos: Number.isNaN(+sessionStorage.getItem('position'))
        ? 0
        : +sessionStorage.getItem('position'),

      currentPosition: 0,
      currentPositionLeap: 0,

      currentYear: 0,
      currentYearLeap: 0,

      currentProcent: 0,
      currentProcentLeap: 0,

      firstElementPosition: 0,
      lastElementPosition: 0,

      firstElementYear: 0,
      lastElementYear: 0,

      mouseX: 0,
      mouseY: 0,

      progress: 0,
    };

    this.stage.isInited = true;
    this.DOM.animation.classList.add('is-inited');
    // --------- BEGIN call to init methods
    this.setHandlers();
    this.setSizes();
    // --------- END call to init methods

    // --------- BEGIN create child managers
    this.timelineManager = new Timeline({
      generalManager: this,
    });
    this.storage.managers.push(this.timelineManager);

    this.sceneManager = new Scene({
      generalManager: this,
    });
    this.storage.managers.push(this.sceneManager);

    this.fullSizeImgManager = new FullSizeImg({
      generalManager: this,
      apiKeys: this.settings.apiKeys,
      fullSizeImg: this.DOM.fullSizeImg,
      closeBtn: this.DOM.closeBtn,
      imgWrapper: this.DOM.imgWrapper,
      descriptionTitle: this.DOM.descriptionTitle,
      description: this.DOM.description,
      descriptionBody: this.DOM.descriptionBody,
      descriptionLink: this.DOM.descriptionLink,
    });
    this.storage.managers.push(this.fullSizeImgManager);
    // --------- END create child managers


    this.apiParser = new MainParser({
      prepearedData: this.stage.prepearedData,
      mainData: this.fullData,
      apiKeys: this.settings.apiKeys,
      onProgressCallback: this.onProgress.bind(this),
      onLoadCallback: this.onLoad.bind(this),
    });
  }

  destroy() {
    this.stage.prepearedData = null;
    const str = JSON.stringify(this.getCurrentData());
    sessionStorage.setItem('handoff-data', str);

    document.querySelector('html').style.overflow = '';
    this.DOM.animation.classList.remove('is-inited');
    this.DOM.animation.classList.remove('is-loaded');
    this.storage.onProgressFunctions.forEach((callback) => {
      this.removeProgressListener(callback);
    });
    this.storage.onLoadFunctions.forEach((callback) => {
      this.removeLoadListener(callback);
    });

    window.removeEventListener('resize', this.resizeFunction);
    this.DOM.sceneContainer.removeEventListener('wheel', this.wheelFunction);
    this.DOM.sceneContainer.removeEventListener(
      'mousemove',
      this.mousemoveFunction
    );
    window.removeEventListener('keydown', this.keydownFunction);

    this.DOM.arrowTop.removeEventListener('click', this.arrowtopFunction);
    this.DOM.arrowBottom.removeEventListener('click', this.arrowbottomFunction);
    this.DOM.timeline.removeEventListener('click', this.timelineClickFunction);

    this.stopTicker();

    this.storage.elements.forEach((el) => el.elementManager.destroy());
    this.fullSizeImgManager.destroy();

    this.storage = {
      managers: [],
      onProgressFunctions: [],
      onLoadFunctions: [],

      elements: [],
      normalPositions: [],
    };

    this.DOM.sceneContainer.innerHTML = '';
  }

  startTicker() {
    this.stage.play = true;
    window.requestAnimationFrame(this.tick.bind(this));
  }

  stopTicker() {
    this.stage.play = false;
  }

  setYear(y, ease = true) {
    let year = Math.round(y);
    if (year > this.stage.lastElementYear) year = this.stage.lastElementYear;
    else if (year < this.stage.firstElementYear)
      year = this.stage.firstElementYear;

    let position = 0;

    for (let i = 0; i < this.storage.normalPositions.length; i++) {
      if (
        this.storage.normalPositions[i] &&
        this.storage.normalPositions[i].year >= year
      ) {
        position = i;
        break;
      }
    }

    this.stage.currentYearLeap = year;
    this.stage.currentPositionLeap = position;
    this.updatePositionLeap();
    this.updateCurrentProcentLeap();
    if (!ease) {
      this.stage.currentYear = year;
      this.stage.currentPosition = position;
    }
  }

  getYear() {
    return this.stage.currentYearLeap;
  }

  addListener(type, callback) {
    if (type === 'progress') this.addProgressListener(callback);
    if (type === 'load') this.addLoadListener(callback);
  }

  removeListener(type, callback) {
    if (type === 'progress') this.removeProgressListener(callback);
    if (type === 'load') this.removeLoadListener(callback);
  }
  // --------- END PUBLIC METHODS

  // --------- BEGIN PRIVAT METHODS
  addProgressListener(callback) {
    this.storage.onProgressFunctions.push(callback);
  }

  removeProgressListener(callback) {
    this.storage.onProgressFunctions = this.storage.onProgressFunctions.filter(
      (fn) => fn !== callback
    );
  }

  addLoadListener(callback) {
    this.storage.onLoadFunctions.push(callback);
  }

  removeLoadListener(callback) {
    this.storage.onLoadFunctions = this.storage.onLoadFunctions.filter(
      (fn) => fn !== callback
    );
  }

  updateProgress(progress) {
    this.stage.progress = progress;

    this.storage.onProgressFunctions.forEach((callback) => {
      callback(progress);
    });
  }

  onProgress(progress) {
    this.updateProgress(progress);

    this.DOM.progressProcent.innerHTML = Math.round(progress * 100);
  }

  onLoad(data) {
    this.storage.elements = data;
    this.createElements();
    this.stage.isLoaded = true;

    setTimeout(() => {
      this.DOM.animation.classList.add('is-loaded');
      this.stage.currentPositionLeap = this.stage.startPos;
      this.updatePositionLeap();
      this.updateCurrentYearLeap();
      this.updateCurrentProcentLeap();

      // this.stage.currentPosition = this.stage.startPos;
      // this.stage.currentYear = this.stage.currentYearLeap;
      // this.stage.currentProcent = this.stage.currentProcentLeap;

      this.storage.onLoadFunctions.forEach((callback) => {
        callback();
      });
    }, 500);
  }

  createPosition(index, elementYear, element) {
    this.storage.normalPositions[index] = {
      year: elementYear,
      number: 1,
      elements: [element],
    };
  }

  addElementToPosition(index, elementYear, element) {
    this.storage.normalPositions[index].year = elementYear;
    this.storage.normalPositions[index].number += 1;
    this.storage.normalPositions[index].elements.push(element);
  }

  createElements() {
    this.storage.normalPositions = [];
    const start =
      this.settings.visibleImages * this.settings.positionDuration + 1 + 10;
    let index = start;

    this.storage.elements.forEach((element, i) => {
      // BEGIN normal-position calculating
      const elementYear = Math.round(element[this.settings.apiKeys.year]);
      if (!this.storage.normalPositions[index])
        this.createPosition(index, elementYear, element);
      else if (
        this.storage.normalPositions[index].year !== elementYear ||
        this.storage.normalPositions[index].number + 1 >
          this.settings.maxNumberInYear
      ) {
        index += this.settings.positionDuration;
        this.createPosition(index, elementYear, element);
      } else {
        this.addElementToPosition(index, elementYear, element);
      }
      element[this.settings.apiKeys.normalPositions] = index;
      // END normal-position calculating

      // BEGIN x and y position
      const { x, y } = this.getCoords(i);
      element[this.settings.apiKeys.x] = x;
      element[this.settings.apiKeys.y] = y;
      // END x and y position

      // BEGIN creating managers
      element.elementManager = new Element({
        generalManager: this,
        elementInfo: element,
        apiKeys: this.settings.apiKeys,
      });
    });

    this.storage.normalPositions.unshift({
      year: this.storage.normalPositions[start]
        ? this.storage.normalPositions[start].year
        : this.settings.firstYear,
    });

    this.stage.firstElementPosition = 0;
    this.stage.lastElementPosition = this.storage.normalPositions.length - 1;

    this.stage.firstElementYear = this.storage.normalPositions[
      this.stage.firstElementPosition
    ].year;
    this.stage.lastElementYear = this.storage.normalPositions[
      this.stage.lastElementPosition
    ].year;
  }

  getCoords(i) {
    const side = i % 4;

    let x = 0;
    let y = 0;

    const imageWidth = 0.2 * this.width;
    const dispersion = imageWidth / 4 + (Math.random() * imageWidth) / 4;

    if (side === 0) {
      x = -imageWidth / 2 - dispersion;
      y = imageWidth / 2 + dispersion;
    }
    if (side === 1) {
      x = imageWidth / 2 + dispersion;
      y = imageWidth / 2 + dispersion;
    }
    if (side === 2) {
      x = imageWidth / 2 + dispersion;
      y = -imageWidth / 2 - dispersion;
    }
    if (side === 3) {
      x = -imageWidth / 2 - dispersion;
      y = -imageWidth / 2 - dispersion;
    }
    return { x, y };
  }

  setHandlers() {
    this.resizeFunction = debounce(this.resize.bind(this), 100);
    this.wheelFunction = this.wheel.bind(this);
    this.mousemoveFunction = this.mouseMove.bind(this);
    this.keydownFunction = this.keydown.bind(this);
    this.arrowtopFunction = this.arrowTopHandler.bind(this);
    this.arrowbottomFunction = this.arrowBottomHandler.bind(this);
    this.timelineClickFunction = this.timelineClick.bind(this);

    window.addEventListener('resize', this.resizeFunction);
    this.DOM.sceneContainer.addEventListener('wheel', this.wheelFunction);
    this.DOM.sceneContainer.addEventListener(
      'mousemove',
      this.mousemoveFunction
    );
    window.addEventListener('keydown', this.keydownFunction);

    this.DOM.arrowTop.addEventListener('click', this.arrowtopFunction);
    this.DOM.arrowBottom.addEventListener('click', this.arrowbottomFunction);
    this.DOM.timeline.addEventListener('click', this.timelineClickFunction);

    this.startTicker();
  }

  timelineClick(event) {
    const { height, top } = this.DOM.timeline.getBoundingClientRect();
    const procent = (height - (event.clientY - top)) / height;
    const year = Math.round(
      lerp(this.settings.firstYear, this.settings.lastYear, procent)
    );

    this.setYear(year);
  }

  arrowTopHandler() {
    this.setYear(this.stage.currentYearLeap + 50);
  }

  arrowBottomHandler() {
    this.setYear(this.stage.currentYearLeap - 50);
  }

  keydown({ key }) {
    if (this.stage.progress !== 1 || this.fullSizeImgManager.isOpen) return;

    const defaultScrollSpeed = 150;

    if (key === 'ArrowUp') {
      this.updatePositionLeap(defaultScrollSpeed * this.settings.speed);
    }
    if (key === 'ArrowDown') {
      this.updatePositionLeap(-defaultScrollSpeed * this.settings.speed);
    }

    this.updateCurrentYearLeap();

    this.updateCurrentProcentLeap();
  }

  mouseMove(event) {
    this.stage.mouseX = (this.width / 2 - event.clientX) / this.width;
    this.stage.mouseY = (this.height / 2 - event.clientY) / this.height;
  }

  wheel({ deltaY }) {
    if (this.stage.progress !== 1) return;

    const normalDelta = deltaY * this.settings.speed;

    this.updatePositionLeap(normalDelta);

    this.updateCurrentYearLeap();

    this.updateCurrentProcentLeap();
  }

  updateYearSettings() {
    const {first: firstYear, last: lastYear} = this.getCurrentYears();

    const STEP = 50;
    const MARKLENGTH = STEP / 6;
    const bottomRemainder = MARKLENGTH * 2;
    const topRemainder = MARKLENGTH * 4;

    const markFirstYear = firstYear - (firstYear % STEP);
    const realFirstYear = markFirstYear - bottomRemainder;
    
    const markLastYear = lastYear - (lastYear % STEP) + STEP;
    const realLastYear = markLastYear + topRemainder;

    this.settings.firstYear = realFirstYear;
    this.settings.lastYear = realLastYear;
    this.updateCurrentProcentLeap();

    // const countOfMarks = Math.floor((markLastYear - markFirstYear) / STEP);
    let years = [];
    for (let year = markFirstYear; year <= markLastYear; year += STEP) {
      years.push( year );
    }
    return years;
  }

  updateSessionStorage() {
    sessionStorage.setItem('position', +this.stage.currentPositionLeap);
  }

  updatePositionLeap(normalDelta = 0) {
    if (
      this.stage.currentPositionLeap + normalDelta >=
      this.stage.lastElementPosition
    ) {
      this.stage.currentPositionLeap = this.stage.lastElementPosition;
      this.updateSessionStorage();
      this.DOM.arrowTop.classList.add('is-disabled');
      return;
    }
    if (
      this.stage.currentPositionLeap + normalDelta <=
      this.stage.firstElementPosition
    ) {
      this.stage.currentPositionLeap = this.stage.firstElementPosition;
      this.updateSessionStorage();
      // this.DOM.animation.classList.remove('is-scrolled');
      this.DOM.arrowBottom.classList.add('is-disabled');
      return;
    }
    // this.DOM.animation.classList.remove('is-scrolled-max');
    // this.DOM.animation.classList.add('is-scrolled');
    this.stage.currentPositionLeap += normalDelta;
    this.updateSessionStorage();
    this.DOM.arrowTop.classList.remove('is-disabled');
    this.DOM.arrowBottom.classList.remove('is-disabled');
  }

  updateCurrentYearLeap() {
    let index = Math.round(this.stage.currentPositionLeap);

    while (
      (!this.storage.normalPositions[index] ||
        !this.storage.normalPositions[index].year) &&
      index > 0
    ) {
      index -= 1;
    }
    this.stage.currentYearLeap = this?.storage?.normalPositions?.[index]?.year;
  }

  updateCurrentProcentLeap() {
    this.stage.currentProcentLeap =
      ((this.stage.currentYearLeap - this.settings.firstYear) /
        (this.settings.lastYear - this.settings.firstYear)) *
      100;
  }

  tick() {
    this.innert('currentPositionLeap', 'currentPosition');
    this.innert('currentYearLeap', 'currentYear');
    this.innert('currentProcentLeap', 'currentProcent');


    if (this.events.onPositions[Math.floor(this.stage.currentPosition)]) {
      
      this.events.onPositions[Math.floor(this.stage.currentPosition)]();
    }

    this.storage.managers.forEach((manager) => {
      if (manager.tick) manager.tick();
    });

    this.storage.elements.forEach((element) => {
      if (element.elementManager) this.opacityManager(element.elementManager);
    });

    if (this.stage.play) window.requestAnimationFrame(this.tick.bind(this));
  }

  innert(from, to, ease = this.settings.ease) {
    this.stage[to] += ease * (this.stage[from] - this.stage[to]);
  }

  opacityManager(elementManager) {
    if (!elementManager.elementInfo) return;
    const year = this.settings.visibleImages * this.settings.positionDuration;
    const visibleDistance =
      this.settings.fullVisibleImages * this.settings.positionDuration;

    // const year = 3 * this.settings.positionDuration;
    // const visibleDistance = 1 * this.settings.positionDuration;

    if (
      // if the element is in visible range
      elementManager.elementInfo[elementManager.apiKeys.normalPositions] -
        this.stage.currentPosition -
        visibleDistance <
        year &&
      elementManager.elementInfo[elementManager.apiKeys.normalPositions] -
        this.stage.currentPosition +
        this.settings.focusDistance >
        0
    ) {
      elementManager.objectCSS.visible = true;
      elementManager.element.style.opacity =
        lerp(
          1,
          0,
          (elementManager.elementInfo[elementManager.apiKeys.normalPositions] -
            this.stage.currentPosition -
            visibleDistance) /
            year
        ) ** 4;
    } else {
      elementManager.objectCSS.visible = false;
    }
  }

  setSizes() {
    this.width = this.DOM.sceneContainer.offsetWidth;
    this.height = this.DOM.sceneContainer.offsetHeight;
  }

  resize() {
    this.setSizes();

    this.storage.managers.forEach((manager) => {
      if (manager.resize) manager.resize();
    });

    this.storage.elements.forEach(({ elementManager }, i) => {
      const { x, y } = this.getCoords(i);

      if (elementManager) {
        elementManager.objectCSS.position.x = x;
        elementManager.objectCSS.position.y = y;
      }

    });
  }
  // --------- END PRIVAT METHODS
}
