const gtmEvents = {
    sendSearchEventAnalytics: function(eventType, keyWords){
		window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : eventType,
            'search_term': keyWords,
        });	
    },
    sendSearchParamsAnalytics: function(url, theme){
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
        'event' : "virtualPageview",
        'site' : "LDT",
        'pageUrl' : url,
        'pageTitle' : "Resultats",
        'page_category': "Resultats de recherche",
        'page_subcategory': theme
        });
    },
    sendVirtualEventPageView: function(title, date, location){
        if( location.href.includes("evenement")){
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event' : 'virtualPageview',
                'site' : "LDT",
                'pageUrl' : location.href,
                'pageTitle' : title,
                'fiche_name':  title,
                'page_category': "Fiches/Evenements",
                'fiche_date': date
            });
        }
    },
     sendVirtualPageView: function(title, location, pagePath ){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'virtualPageview',
            'site' : "LDT",
            'pageUrl' : window.location.href,
            'page_category' : pagePath,
            'pageTitle' : title
        });
    }
}

export default gtmEvents;