import styled, { css } from 'styled-components';
import Typography from 'components/UI/Typography';

const openAccordionStyle = css`
  padding: 30px 20px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  background: ${(props) => props.theme.colors.light_purple};

  > .filterAccordionBody {
    display: block;
  }

  > .filterAccordionHead > .filterAccordionArrow {
    transform: rotate(180deg);
  }

  .filterAccordionCounter {
    display: none;
  }

  .filterAccordion {
    background: transparent;
  }
`

export const StyledFilterAccordion = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.blue};
  margin-bottom: -1px;
  
  &.isDisabled {
    opacity: .5;

    > .filterAccordionHead, .filterAccordionArrow {
      cursor: not-allowed;
    }
  }

  ${props => props.isOpen && openAccordionStyle}
`;

export const Title = styled(Typography)`
  font-size: ${(props) => props.theme.fonts.h3};
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
  transition: color 0.3s ease;

  p {
    transition: color 0.3s ease;
  }
`;

export const Head = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  :hover {
    ${Title} {
      p {
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 50%;
  color: ${(props) => props.theme.colors.blue};
  font-weight: 500;
  font-size: ${(props) => props.theme.fonts.overline_small};
  line-height: 14px;

  &.isDisplay {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  display: none;
  padding-top: 30px;
`;

export const Button = styled.div`
  width: 20px;
  height: 20px;
  background-image: url("/images/arrow-down.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer
`;
