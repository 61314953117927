// import { isEmpty } from 'lodash'
import gtmEvents from './gtmFunctions.js';

export const searchParams = (terms, states, functions) => {

  const searchQueryPayload = [
    {
      rawTitle: 'filtredThemes',
      title: 'theme',
      values: terms.filtredThemes ? terms.filtredThemes : [],
    },
    {
      rawTitle: 'filtredNations',
      title: 'nation',
      values: terms.filtredNations ? terms.filtredNations : [],
    },
    {
      rawTitle: 'filtredRegions',
      title: 'region',
      values: terms.filtredRegions ? terms.filtredRegions : []
    },
    {
      rawTitle: 'filteredCompetences',
      title: 'competence',
      values: terms.filteredCompetences ? terms.filteredCompetences : []
    },
    {
      rawTitle: 'yearFrom',
      title: 'year_from',
      values: terms.yearFrom ? terms.yearFrom : '',
    },
    { 
      rawTitle: 'yearTo',
      title: 'year_to',
      values: terms.yearTo ? terms.yearTo : '',
    },
    {
      rawTitle: 'keywordInput',
      title: 'keyword',
      values: terms.keywordInput ? terms.keywordInput : ''
    }
  ]

  const queryString = `?${searchQueryPayload.map(item => `${item.title}=${item?.values?.length > 0 && typeof item.values !== 'string' ? item?.values?.map(i => i?.terme)?.join(',') : item.values}`).join('&')}`;

  window.history.pushState({}, '', `/${queryString}`);

  const url = new URL(window.location.href)
  const searchQuery = new URLSearchParams(url.search);

  gtmEvents.sendSearchParamsAnalytics(url.href, searchQuery.get('theme'))

  const str = JSON.stringify({
    filtredThemes: terms.filtredThemes,
    filtredNations: terms.filtredNations,
    filtredRegions: terms.filtredRegions,
    filteredCompetences: terms.filteredCompetences,
    yearFrom: terms.yearFrom,
    yearTo: terms.yearTo,
    keywordInput: terms.keywordInput,
    filtredEvents: terms.result,
    filtredYears: {
      from: terms.yearFrom,
      to: terms.yearTo,
    },
    dropdownText: functions.getDropdownText(terms.filtredNations),
  });

  states.set(str);
}