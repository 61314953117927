import React, {useState} from 'react';
import {
  StyledFilterAccordion,
  Head,
  Title,
  Counter,
  Container,
  Body,
  Button,
} from './styles.js'

const FilterAccordion = ({children, title, counter, isDisable}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  }

  return (
    <StyledFilterAccordion className={isDisable ? 'filterAccordion isDisabled' : 'filterAccordion'} isOpen={isDisable ? false : isOpen}>
      <Head className="filterAccordionHead" onClick={() => {toggleAccordion()}}>
        <Container>
          <Title>{title}</Title>
          <Counter className={counter === 0 ? 'filterAccordionCounter isDisplay' : 'filterAccordionCounter'}>
            {counter}
          </Counter>
        </Container>

        <Button role="button" className="filterAccordionArrow" type="button"></Button>
      </Head>

      <Body isOpen={isOpen} className="filterAccordionBody">
        {children}
      </Body>
    </StyledFilterAccordion>
  )
}

export default FilterAccordion;
