import React, { useState, useEffect } from 'react';
import { useSessionStorage } from 'react-use';
import { graphql } from 'gatsby';
import Timeline from 'components/Timeline';
import Search from 'components/Search';
import FilteredSearchInfo from 'components/FilteredSearchInfo';
import ResolutionChecker from 'components/ResolutionChecker';
import { List, ListItem } from '../components/Search/styles';
import FilterByNations from '../components/FilterByNations/index.js';
import CheckboxItem from '../components/Search/components/CheckboxItem/index.js';
import { searchParams } from '../utils/functions/searchParams';
import gtmEvents from '../utils/functions/gtmFunctions.js';
import {CookieConsent} from "react-cookie-consent";
import SecurityHeader from "../components/Security/Security";

const Index = (props) => {
  const [sSFiltredData, setSSFiltredData] = useSessionStorage('filtered-data', null);
  const [restartAnimation, setRestartAnimation] = useSessionStorage('restart-animation','false');

  const dataSS = sSFiltredData ? JSON.parse(sSFiltredData) : null;

  const [filtredEvents, setFiltredEvents] = useState(() => dataSS?.filtredEvents ?? null);
  const [filtredYears, setFiltredYears] = useState(() => dataSS?.filtredYears ?? { from: '', to: '' });
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isOpenFilterByNations, setIsOpenFilterByNations] = useState(false);
  const [earlierAndLatestEventDates, setEarlierAndLatestEventDates] = useState(null);
  const [dropdownText, setDropdownText] = useState(() => dataSS?.dropdownText ?? 'Choisir mon option');
  const [countOfEvents, setCountOfEvents] = useState(null);
  const [filteredEventsTitles, setFilteredEventsTitles] = useState([]);
  const [filtredThemes, setFiltredThemes] = useState(() => dataSS?.filtredThemes ?? []);
  const [filtredNations, setFiltredNations] = useState(() => dataSS?.filtredNations ?? []);
  const [filtredRegions, setFiltredRegions] = useState(() => dataSS?.filtredRegions ?? []);
  const [filteredDomaines, setFilteredDomaines] = useState(() => dataSS?.filteredDomaines ?? []);
  const [filteredDisciplines, setFilteredDisciplines] = useState(() => dataSS?.filteredDisciplines ?? []);
  const [filteredCompetences, setFilteredCompetences] = useState(() => dataSS?.filteredCompetences ?? []);
  const [yearFrom, setYearFrom] = useState(() => dataSS?.filtredYears.from ?? '');
  const [yearTo, setYearTo] = useState(() => dataSS?.filtredYears.to ?? '');
  const [keywordInput, setKeywordInput] = useState(() => dataSS?.keywordInput ?? '');

  const keywords = [...props.data.allTimelineTerms.edges[0].node.mots_cles, ...props.data.allTimelineEvents.edges[0].node.Events, ...props.data.allTimelineEvents.edges[0].node.BackgroundEvents];

  const getDropdownText = (nations) => {
    const defaultText = 'Choisir mon option';

    if (nations.length === 0) {
      setDropdownText(defaultText);
      return defaultText;
    } else {
      const titles = filtredNations.flatMap((nation, index) => {
        if (index === filtredNations.length - 1) {
          return nation.terme + '';
        } else {
          return nation.terme + ', ';
        }
      });
      setDropdownText(titles);
      return titles;
    }
  };

  const openFilterByNations = () => setIsOpenFilterByNations(true)
  const closeFilterByNations = () => setIsOpenFilterByNations(false)
  const openSearch = () => setIsOpenSearch(true);
  const closeSearch = () => setIsOpenSearch(false);
  const getCountOfEvents = (value) => setCountOfEvents(value);

  const getEventsByKeywords = (data, string) => {
    const sortKeywords = (array, str) => {
      const convertHtmlToText = (html) => {
        const p = document.createElement('p');
        p.innerHTML = html;
        return p.innerText;
      }

      const specialCharacters = (string) => {
        let newStr = string;
        if (string.includes('\'') || string.includes('`') || string.includes('’')) {
          newStr = string.replace(/'|`|’/g, '');
        }

        return newStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }

      const searchStr = specialCharacters(str.trim().toLowerCase());

      const filteredArrayTerme = array.filter(
        (item) => {
          if (item.terme) {
            return ~specialCharacters(item.terme).trim().toLowerCase().indexOf(searchStr)
          }
        }
      );

      const filteredArrayTitle = array.filter(
        (item) => {
          if (item.Title) {
            return ~specialCharacters(convertHtmlToText(item.Title)).trim().toLowerCase().indexOf(searchStr)
          }
        }
      );

      const filteredArrayBody = array.filter(
        (item) => {
          if (item.Body) {
            return ~specialCharacters(convertHtmlToText(item.Body)).trim().toLowerCase().indexOf(searchStr)
          }
        }
      );

      const filteredArrayBAnQDescription = array.filter(
        (item) => {
          if (item.BAnQDescription) {
            return ~specialCharacters(convertHtmlToText(item.BAnQDescription)).trim().toLowerCase().indexOf(searchStr)
          }
        }
      );

      const filteredArrayDescriptionFirme = array.filter(
        (item) => {
          if (item.DescriptionFirme) {
            return ~specialCharacters(convertHtmlToText(item.DescriptionFirme)).trim().toLowerCase().indexOf(searchStr)
          }
        }
      );

      const filteredArrayImageCredit = array.filter(
        (item) => {
          if (item.ImageCredit) {
            return ~specialCharacters(convertHtmlToText(item.ImageCredit)).trim().toLowerCase().indexOf(searchStr)
          }
        }
      );

      const filteredArrayDescriptionPersonnages = array.filter(
        (item) => {
          if (item.personnages) {
            const personnages = item.personnages.filter(i => ~specialCharacters(convertHtmlToText(i.terme)).trim().toLowerCase().indexOf(searchStr))
            if (personnages.length) {
              return item;
            }
          }
        }
      );

      const filteredArray = [...filteredArrayTerme, ...filteredArrayTitle, ...filteredArrayBody, ...filteredArrayBAnQDescription, ...filteredArrayDescriptionFirme, ...filteredArrayImageCredit, ...filteredArrayDescriptionPersonnages];

      return filteredArray.flatMap(item => {
        let arr = [];
        if (item.terme) {
          arr = [...arr, item.evenements]
        }
        if (item.Title || item.Body || item.BAnQDescription || item.DescriptionFirme || item.personnages) {
          arr = [...arr, item.EventId]
        }
        return arr;
      });
    };
    return sortKeywords(data, string);
  };

  const getFilterHandler = (data, setter) => {
    return (item, isChecked) => {
      if (isChecked) {
        setter([...data, item]);
      } else if (!isChecked) {
        setter(
          data.filter((dataItem) => {
            return dataItem.id !== item.id;
          })
        );
      }
    };
  };

  const getFiltredThemes = getFilterHandler(filtredThemes, setFiltredThemes);
  const getFiltredNations = getFilterHandler(filtredNations, setFiltredNations);
  const getFiltredRegions = getFilterHandler(filtredRegions, setFiltredRegions);
  const getFilteredCompetences = getFilterHandler(filteredCompetences, setFilteredCompetences);

  const handleOnResetClick = () => {
    setSSFiltredData(null);
    setFiltredEvents(null);
    setFiltredYears({ from: '', to: '' });
    setFiltredThemes([]);
    setFiltredNations([]);
    setFiltredRegions([]);
    setFilteredCompetences([]);
    setYearFrom('');
    setYearTo('');
    setKeywordInput('');
    setCountOfEvents(null);
    setFilteredEventsTitles([]);
    setDropdownText('Choisir mon option');
    setEarlierAndLatestEventDates({ first: '1534', last: '2020' });
    setRestartAnimation('true');
    window.history.pushState({}, '', `/`);
  };

  const getActivatedFilteredAndThemTitles = () => {

    const themesEvents = filtredThemes.flatMap((theme) => theme.terme);
    const nationsEvents = filtredNations.flatMap((nation) => nation.terme);
    const regionsEvents = filtredRegions.flatMap((region) => region.terme);
    const competencesEvents = filteredCompetences.flatMap((competence) => competence.terme);

    const filtredEventsTitles = themesEvents.concat(
      nationsEvents,
      regionsEvents,
      competencesEvents
    );

    const trimmedKeywordInput = keywordInput.trim();

    if (trimmedKeywordInput !== '') {
      if (trimmedKeywordInput.includes(',')) {
        if (trimmedKeywordInput[trimmedKeywordInput.length - 1] === ',') {
          const newKeywords = trimmedKeywordInput.slice(0, trimmedKeywordInput.length - 1);
          newKeywords.split(',').map(item => filtredEventsTitles.push(item));
        } else {
          trimmedKeywordInput.split(',').map(item => filtredEventsTitles.push(item));
        }
      } else {
        filtredEventsTitles.push(trimmedKeywordInput);
      }
    }

    if (yearTo !== '') {
      filtredEventsTitles.push(yearTo);
    }
    if (yearFrom !== '') {
      filtredEventsTitles.push(yearFrom);
    }

    setFilteredEventsTitles(filtredEventsTitles);
  };

  useEffect(() => {
    getActivatedFilteredAndThemTitles();
  }, []);

  const handleOnSearchClick = () => {

    let resultByKeywords = [];
    let trimmedKeywordInput = keywordInput.trim();

    if (trimmedKeywordInput[trimmedKeywordInput.length - 1] === ',') {
      trimmedKeywordInput = trimmedKeywordInput.slice(0, trimmedKeywordInput.length -1);
    }

    if (trimmedKeywordInput === '') {
      resultByKeywords = [];
    } else {
      if (trimmedKeywordInput.includes(',')) {
        const keywordsResult = trimmedKeywordInput.split(',');
        keywordsResult.map(item => {
          const result = getEventsByKeywords(keywords, item);
          resultByKeywords.push(result);
        })
        resultByKeywords = resultByKeywords.flatMap((item) => item);
      } else {
        resultByKeywords = getEventsByKeywords(keywords, trimmedKeywordInput);
      }
    }

    setFiltredYears({ from: yearFrom, to: yearTo });
    const themesEvents = filtredThemes.flatMap((theme) => theme.evenements);
    const nationsEvents = filtredNations.flatMap((nation) => nation.evenements);
    const regionsEvents = filtredRegions.flatMap((region) => region.evenements);
    const competencesEvents = filteredCompetences.flatMap((competence) => competence.evenements);
    const keywordsEvents = resultByKeywords.flatMap((item) => item);

    const result = themesEvents.concat(
      nationsEvents,
      regionsEvents,
      competencesEvents,
      keywordsEvents
    );

    if (!result || result.length === 0) {
      gtmEvents.sendSearchEventAnalytics("no_search_result", keywordInput);
      setFiltredEvents(null);
    } else {
      gtmEvents.sendSearchEventAnalytics("search_result", keywordInput);
      setFiltredEvents(result);
    }

    getActivatedFilteredAndThemTitles();
    closeSearch();

    searchParams(
      // Terms
      {
        filtredThemes,
        filtredNations,
        filtredRegions,
        filteredCompetences,
        yearFrom,
        yearTo,
        keywordInput,
      },
      // States
      {
        set: setSSFiltredData,
        get: sSFiltredData,
      },
      // Functions
      {
        getDropdownText
      }
    );

    setRestartAnimation('true');

  };

  /*console.log('keywordInput 290', keywordInput)
  console.log('filtredEvents 291', filtredEvents)
  console.log('countOfEvents 292', countOfEvents)*/

  return (
    <>
      <CookieConsent debug={false}
                     location="top"
                     buttonText="X"
                     cookieName="LigneDuTempsCookie"
                     style={{ background: "#2B373B" }}
                     buttonStyle={{ background: "#2B373B", color: "white", fontWeight: 800 }}
                     expires={150}
      >
        <p>
          Les sites Web de BAnQ utilisent des fichiers témoins (souvent appelés cookies) pour assurer leur bon fonctionnement et une expérience utilisateur optimale. Par votre accès aux sites, vous acceptez l’utilisation de ces témoins. Pour en savoir plus, consultez notre <a style={{color: "#7e8bc3" }} href="https://www.banq.qc.ca/politique-de-confidentialite/">Politique de confidentialité</a>.
        </p>
      </CookieConsent>
      <SecurityHeader />
      <ResolutionChecker>
        <FilteredSearchInfo
          filteredEventsTitles={filteredEventsTitles}
          countOfEvents={countOfEvents}
          openSearch={openSearch}
          handleOnResetClick={handleOnResetClick}
          earlierAndLatestEventDates={earlierAndLatestEventDates}
        ></FilteredSearchInfo>
        <Search
          data={props.data.allTimelineTerms.edges[0].node}
          closeSearch={closeSearch}
          isOpen={isOpenSearch}
          handleOnSearchClick={handleOnSearchClick}
          filtredThemes={filtredThemes}
          setFiltredThemes={setFiltredThemes}
          filtredNations={filtredNations}
          setFiltredNations={setFiltredNations}
          filtredRegions={filtredRegions}
          setFiltredRegions={setFiltredRegions}
          filteredDomaines={filteredDomaines}
          setFilteredDomaines={setFilteredDomaines}
          filteredCompetences={filteredCompetences}
          setFilteredCompetences={setFilteredCompetences}
          filteredDisciplines={filteredDisciplines}
          setFilteredDisciplines={setFilteredDisciplines}
          yearFrom={yearFrom}
          setYearFrom={setYearFrom}
          yearTo={yearTo}
          setYearTo={setYearTo}
          keywordInput={keywordInput}
          setKeywordInput={setKeywordInput}
          handleOnResetClick={handleOnResetClick}
          earlierAndLatestEventDates={earlierAndLatestEventDates}
          getFiltredThemes={getFiltredThemes}
          getFiltredNations={getFiltredNations}
          getFiltredRegions={getFiltredRegions}
          getFilteredCompetences={getFilteredCompetences}
          keywords={keywords}
          setSSFiltredData={setSSFiltredData}
          filtredEvents={filtredEvents}
          getDropdownText={getDropdownText}
        />
        <FilterByNations
          title="L'histoire du Québec selon…"
          handleOnSearchClick={handleOnSearchClick}
          closeFilterByNations={closeFilterByNations}
          isOpenFilterByNations={isOpenFilterByNations}
          children={
            <List>
              {props.data.allTimelineTerms.edges[0].node.nations.map(
                (nation, index) => {
                  return (
                    <ListItem key={nation?.id}>
                      <CheckboxItem
                        item={nation}
                        handleOnChange={getFiltredNations}
                        isChecked={
                          !!filtredNations.find((item) => item.id === nation.id)
                        }
                      />
                    </ListItem>
                  );
                }
              )}
            </List>
          }
        ></FilterByNations>
        <Timeline
          {...props}
          filtredEvents={filtredEvents}
          filtredYears={filtredYears}
          getCountOfEvents={getCountOfEvents}
          setEarlierAndLatestEventDates={setEarlierAndLatestEventDates}
          openFilterByNations={openFilterByNations}
          dropdownText={dropdownText}
          restartAnimation={restartAnimation}
          setRestartAnimation={setRestartAnimation}
          nationsItemsLength={props?.data?.allTimelineTerms?.edges?.[0]?.node?.nations?.length}
        />
        <div className='timeline-animation__mouse-prompt' style={{width:'100%'}}>
          Utilisez la souris ou les flèches pour
          <br /> faire défiler les Lignes du temps.
           <div
               style={{textAlign:'center',width:'100%', marginBottom:'-20px'}}>
              Ce projet a été réalisé avec le généreux soutien de la Fondation de BAnQ et de Québecor.
          </div>
          <div style={{float:'right', marginRight:'150px', color:'#7E8BC3'}}>
             <a href='https://www.banq.qc.ca/politique-de-confidentialite/' style={{color:'#7E8BC3', textDecoration: 'underline',}}
                target="_blank" rel="noopener noreferrer">Confidentialité</a>
          </div>

        </div>

      </ResolutionChecker>
    </>
  );
};

export default Index;

export const query = graphql`
  query {
    allTimelineEvents {
      edges {
        node {
          BackgroundEvents {
            BAnQDescription
            EndDisplayYear
            EndDisplay
            EventId
            JulianDayEnd
            JulianDayStart
            StartDisplay
            StartDisplayYear
            Title
          }
          Body
          EarliestJulianDay
          EndDateDisplay
          Events {
            BAnQ_LastUpdateDate
            BAnQ_ThumbnailNoticePath
            Body
            DescriptionFirme
            FeatureImagePath
            EventId
            ImageCredit
            LinkTarget
            JulianDayStart
            LinkText
            Priority
            StartDisplay
            ThumbnailPath
            StartDisplayYear
            Title
            Year
            personnages {
              id
              terme
            }
          }
          FeatureImagePath
          IsOpenEnded
          LastJulianDay
          StartDateDisplay
          Title
          id
        }
      }
    }
    allTimelineTerms {
      edges {
        node {
          municipalites {
            evenements
            id
            terme
          }
          mots_cles {
            evenements
            id
            terme
          }
          regions {
            evenements
            id
            terme
          }
          thematiques {
            evenements
            id
            terme
          }
          nations {
            evenements
            id
            terme
          }
          domaines {
            id
            terme
            evenements
          }
          disciplines {
            id
            terme
            evenements
          }
          competences {
            id
            terme
            evenements
          }
          DDC {
            DOMAINES {
              id
              terme
              DISCIPLINES {
                id
                terme
                COMPETENCES {
                  id
                  terme
                }
              }
            }
          }
        }
      }
    }
  }
`;
